<script setup lang="ts">
import type { ApiFileFolderRootFolder, CustomFolderTreeOption } from '~/types'

const props = defineProps<{
  payload: CustomFolderTreeOption
  folderTree: CustomFolderTreeOption[]
  rootFolder: ApiFileFolderRootFolder
  entity: FileFolderEntity
}>()
defineEmits<{ (e: 'close'): void }>()

const folderKeyToMove = computed(() => props.payload.nodeType === 'folder' ? props.payload.key : undefined)
const treeForSelectFolder = computed(() => createTreeToSelectFolder(props.folderTree))

/** Utility */
function createTreeToSelectFolder(tree: CustomFolderTreeOption[], isParentFolderDisabled = false): CustomFolderTreeOption[] {
  /**
   * - Filter out non-folder nodes
   * - Set `disabled` to `true` for the current folder
   */
  return tree
    .filter(node => node.nodeType === 'folder') // Keep only folder nodes
    .map((folderNode) => {
      const disabled = isParentFolderDisabled || folderKeyToMove.value === folderNode.key
      return {
        ...folderNode,
        disabled,
        children: folderNode.children ? createTreeToSelectFolder(folderNode.children, disabled) : folderNode.children,
      }
    })
}
</script>

<template>
  <ThePopup
    :title="$t('fileFolder.modal.move.title', { name: payload.label })"
    width="1000px"
    show
    @close="$emit('close')"
  >
    <FileFolderPage
      :folder-tree="treeForSelectFolder"
      :root-folder
      :mode-props="{
        mode: 'selectFolder',
        currentNode: payload,
        close: () => $emit('close'),
      }"
      :entity
    />
  </ThePopup>
</template>
